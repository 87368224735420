<template>
  <ok-cancel-dialog
    :show="show"
    caption="社員検索"
    ok-btn-text="選択"
    cancel-btn-text="キャンセル"
    min-width="960px"
    max-width="1100px"
    max-height="500px"
    @ok-btn-click="okBtnClickEvent"
    @cancel-btn-click="$emit('cancel-btn-click')"
    :class="{ tablet: tablet }"
    :tablet="tablet"
  >
    <template v-slot:subTitleContents>
      <error-message-panel
        title="エラーが発生しました。下記の内容を確認してください。"
        :messages="errorMessagesData"
        class="mt-1"
        v-if="errorMessagesData.length > 0"
      />
      <div class="d-flex align-center justify-center pt-1">
        <expansion-panels :model.sync="panelStatesData">
          <expansion-panel title="検索条件" :tablet="tablet">
            <template v-slot:content>
              <div class="d-flex align-center">
                <employee-filter
                  :filter-selected1.sync="filterStringData1"
                  :filter-selected2.sync="filterStringData2"
                  :tablet="tablet"
                />
              </div>
            </template>
          </expansion-panel>
        </expansion-panels>
      </div>
    </template>

    <template v-slot:contents>
      <div class="search-dialog-contents">
        <employee-list
          :listItems="_employeeNameItems"
          :selected.sync="selectedData"
          :tablet="tablet"
        />
      </div>
    </template>
  </ok-cancel-dialog>
</template>

<script>
  import okCancelDialog from "@/components/molecules/dialog/okCancelDialog.vue";
  import employeeFilter from "@/components/organisms/searchDialog/employeeFilter.vue";
  import employeeList from "@/components/organisms/searchDialog/employeeList.vue";
  import expansionPanel from "@/components/molecules/panel/expansionPanel.vue";
  import expansionPanels from "@/components/molecules/panel/expansionPanels.vue";
  import errorMessagePanel from "@/components/molecules/panel/errorMessagePanel.vue";
  import dateHelper, { FORMAT_DATE_STRING } from "@/common/date.js";
  import tableHelper from "@/common/table.js";
  import typeHelper from "@/common/type.js";
  import { EMPLOYEE_CLASS_TYPE } from "@/common/const.js";
  import { default as qs } from "qs";
  const QUERY_EMPLOYEE_SEARCH_DLG_KEY = Object.freeze({
    EMPLOYEE_CLASS: "employeeClass",
    END_DATE: "endDate",
  });
  export default {
    components: {
      okCancelDialog,
      employeeFilter,
      employeeList,
      expansionPanel,
      expansionPanels,
      errorMessagePanel,
    },
    data: function () {
      return {
        panelStatesData: 0,
        filterStringData1: null,
        filterStringData2: null,
        selectedData: "",
        employeeNameItemsData: [],
        errorMessagesData: [],
      };
    },
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      employeeClass: {
        type: String,
        default: null,
      },
      endDate: {
        type: Date,
        default: null,
      },
      tablet: {
        type: Boolean,
        default: false,
      },
      proxy: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      _filterString: {
        get() {
          return this.filterStringData;
        },
        set(value) {
          this.filterStringData = value;
        },
      },
      _employeeNameItems() {
        let selectItems = [];
        let filterData = tableHelper.getInitialItems(
          this.filterStringData1,
          this.filterStringData2
        );
        if (this._filterString !== "") {
          selectItems = this.employeeNameItemsData.filter((item) => {
            let match = false;
            filterData.forEach((item2) => {
              if (item.text.startsWith(item2) || item.kana.startsWith(item2)) {
                match = true;
                return;
              }
            });
            return match;
          });
        }
        return selectItems;
      },
    },
    methods: {
      initDialog() {
        this.filterStringData1 = null;
        this.filterStringData2 = null;
        this.selectedData = "";
        this.panelStatesData = 0;
        this.errorMessagesData = [];
      },
      okBtnClickEvent() {
        let selectedItem = null;
        selectedItem = this.employeeNameItemsData.find(
          (item) => item.value === this.selectedData
        );
        if (typeHelper.isNull(selectedItem)) {
          this.errorMessagesData = ["社員が選択されていません。"];
        } else {
          this.$emit("ok-btn-click", selectedItem);
        }
      },
      getListData() {
        let params = {
          [QUERY_EMPLOYEE_SEARCH_DLG_KEY.EMPLOYEE_CLASS]: this.employeeClass,
          [QUERY_EMPLOYEE_SEARCH_DLG_KEY.END_DATE]:
            this.endDate !== null
              ? dateHelper.formatString(
                  this.endDate,
                  FORMAT_DATE_STRING.TYPE.YYYY_MM_DD
                )
              : null,
        };
        const query = qs.parse(qs.stringify(params, { skipNulls: true }));
        this.axios
          .get("/api/employees/dialog", { params: query })
          .then((res) => {
            this.employeeNameItemsData = res.data.employees;
          })
          .catch((err) => {
            if (err.apiErrorMessages) {
              this.errorMessagesData = err.apiErrorMessages;
            } else {
              throw err;
            }
          });
      },
      getListProxyData() {
        let params = {
          [QUERY_EMPLOYEE_SEARCH_DLG_KEY.EMPLOYEE_CLASS]: this.employeeClass,
          [QUERY_EMPLOYEE_SEARCH_DLG_KEY.END_DATE]:
            this.endDate !== null
              ? dateHelper.formatString(
                  this.endDate,
                  FORMAT_DATE_STRING.TYPE.YYYY_MM_DD
                )
              : null,
        };
        const query = qs.parse(qs.stringify(params, { skipNulls: true }));
        this.axios
          .get("/api/employees/proxy-dialog", { params: query })
          .then((res) => {
            this.employeeNameItemsData = res.data.employees;
          })
          .catch((err) => {
            if (err.apiErrorMessages) {
              this.errorMessagesData = err.apiErrorMessages;
            } else {
              throw err;
            }
          });
      },
    },
    mounted: function () {
      this.initDialog();
      if (this.proxy) {
        this.getListProxyData();
      } else {
        this.getListData();
      }
    },
  };
</script>

<style scoped>
  .search-dialog-contents {
    min-height: 300px;
  }
</style>
